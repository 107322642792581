<template>
	<div>
		<dd-card class="box-card" :title="'4. Setari rulare'" :disabled="steps.four.disabled"
			:collapsed.sync="steps.four.collapsed" @toggledCard="toggledCard" v-loading="loading[4]">
			<el-button :loading="isLoading" type="primary" class="fullWidth" @click="saveRunPreference">Salveaza
			</el-button>
			<div class="separator"></div>

			<div v-if="this.autodownload" style="text-align: center">
				<el-switch v-model="useAutoDownload" :active-text="$t('feeds.general.autodownloadEnabled')"
						   :active-value=1
						   :inactive-value=0
						   :inactive-text="$t('feeds.general.autodownloadDisabled')">
				</el-switch>
			</div>
			<div class="separator" v-if="!useAutoDownload"></div>
			<div class="separator" v-if="!useAutoDownload"></div>

			<el-row :gutter="10" v-if="!useAutoDownload">
				<el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
					<div class="title">Alege zilele</div>
					<el-transfer :titles="['Zile disponibile', 'Selectate']" v-model="runTimePrefs.days" :data="days">
					</el-transfer>
				</el-col>
				<el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
					<div class="title">Alege orele</div>
					<el-transfer :titles="['Ore disponibile', 'Selectate']" v-model="runTimePrefs.hours"
						:data="availableHours">
					</el-transfer>
				</el-col>
			</el-row>
			<div class="separator"></div>
			<el-button :loading="isLoading" type="primary" class="fullWidth" @click="saveRunPreference">Salveaza
			</el-button>
		</dd-card>
	</div>

</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	const DDCard = () => import('@/components/DDCard');
	import {
		EventBus
	} from '@/utils/Bus.js';
	import {
		mapGetters,
		mapState
	} from 'vuex';
	export default {
		name: 'feed-step-4',
		components: {
			'dd-card': DDCard,
			Box
		},
		data() {
			return {
				isDisabled: true,
				days: [{
						key: 'mon',
						label: 'Luni',
						disabled: false
					},
					{
						key: 'tue',
						label: 'Marti',
						disabled: false
					},
					{
						key: 'wed',
						label: 'Miercuri',
						disabled: false
					},
					{
						key: 'thu',
						label: 'Joi',
						disabled: false
					},
					{
						key: 'fri',
						label: 'Vineri',
						disabled: false
					},
					{
						key: 'sat',
						label: 'Sambata',
						disabled: false
					},
					{
						key: 'sun',
						label: 'Duminica',
						disabled: false
					},
				],
				hours: [{
						key: '07:00',
						label: '07:00',
						disabled: false
					},
					{
						key: '08:00',
						label: '08:00',
						disabled: false
					},
					{
						key: '09:00',
						label: '09:00',
						disabled: false
					},
					{
						key: '10:00',
						label: '10:00',
						disabled: false
					},
					{
						key: '11:00',
						label: '11:00',
						disabled: false
					},
					{
						key: '12:00',
						label: '12:00',
						disabled: false
					},
					{
						key: '13:00',
						label: '13:00',
						disabled: false
					},
					{
						key: '14:00',
						label: '14:00',
						disabled: false
					},
					{
						key: '15:00',
						label: '15:00',
						disabled: false
					},
					{
						key: '16:00',
						label: '16:00',
						disabled: false
					},
					{
						key: '17:00',
						label: '17:00',
						disabled: false
					},
					{
						key: '18:00',
						label: '18:00',
						disabled: false
					},
					{
						key: '19:00',
						label: '19:00',
						disabled: false
					},
					{
						key: '20:00',
						label: '20:00',
						disabled: false
					},
					{
						key: '21:00',
						label: '21:00',
						disabled: false
					},
					{
						key: '22:00',
						label: '22:00',
						disabled: false
					},
					{
						key: '23:00',
						label: '23:00',
						disabled: false
					},
					{
						key: '00:00',
						label: '00:00',
						disabled: false
					},
					{
						key: '01:00',
						label: '01:00',
						disabled: false
					},
					{
						key: '02:00',
						label: '02:00',
						disabled: false
					},
					{
						key: '03:00',
						label: '03:00',
						disabled: false
					},
					{
						key: '04:00',
						label: '04:00',
						disabled: false
					},
					{
						key: '05:00',
						label: '05:00',
						disabled: false
					},
					{
						key: '06:00',
						label: '06:00',
						disabled: false
					},
				]
			};
		},
		methods: {
			saveRunPreference() {
				if (this.runTimePrefs.days.length > 0 && this.runTimePrefs.hours.length > 0 || this.useAutoDownload != this.feedDetails.autotime) {
					const runtime = {
						repeat: this.runTimePrefs.days.length,
						weekdays: this.runTimePrefs.days,
						hour: this.runTimePrefs.hours
					};

					this.$store.dispatch('feeds/SaveRunTimePrefs', {
						id: this.feedId,
						data: runtime,
						useAutoDownload: +this.useAutoDownload
					}).then((res) => {
						if (typeof res.message !== 'undefined') {
							this.sbMsg({
								type: 'success',
								message: 'Datele au fost salvate'
							});
						}
					});
				} else {
					this.sbMsg({
						type: 'warn',
						message: 'Nimic de salvat'
					});
				}
			},
			toggledCard(status) {
				this.$store.commit('feeds/TOGGLE_COLLAPSE', {
					status,
					step: 'four'
				});
			},
			setSavedRunTime() {
				try {
					const runtime = JSON.parse(this.feedDetails.runtime);
					for (const day in runtime.weekdays) {
						if (!this.runTimePrefs.days.includes(runtime.weekdays[day])) {
							this.runTimePrefs.days.push(runtime
								.weekdays[day]);
						}
					}
					for (const hour in runtime.hour) {
						if (!this.runTimePrefs.hours.includes(runtime.hour[hour])) {
							this.runTimePrefs.hours.push(runtime
								.hour[hour]);
						}
					}
				} catch (e) {
					this.sbMsg({
						type: 'warn',
						message: 'Setarile pentru rulare sunt invalide. Selectati din nou zilele si orele'
					});
				}
			}
		},
		computed: {
			...mapGetters({
				'feedDetails': 'feeds/feedDetails',
				'autodownload': 'feeds/autodownload',
				'feedId': 'feeds/feedId',
				'feedValidated': 'feeds/feedValidated',
				'hasParsedFields': 'feeds/hasParsedFields',
				'feedStep': 'feeds/feedStep',
				'latestCompletedStep': 'feeds/latestCompletedStep',
				'isLoading': 'feeds/isLoading'
			}),
			...mapState({
				steps: (state) => state.feeds.steps,
				fieldAssocSelected: (state) => state.feeds.fieldAssocSelected,
				assocProductContainer: (state) => state.feeds.assocProductContainer,
				rawFields: (state) => state.feeds.feedRawFields,
				parsedFields: (state) => state.feeds.parsedFields,
				loading: (state) => state.feeds.loading,
				rawCategoryAssoc: (state) => state.feeds.rawCategoryAssoc,
				categoryAssoc: (state) => state.feeds.categoryAssoc,
				runTimePrefs: (state) => state.feeds.runTimePrefs
			}),
			useAutoDownload: {
				get() {
					return this.$store.state.feeds.feedAutotime;
				},
				set(val) {
					this.$store.commit('feeds/SET_FEED_USEAUTODOWNLOAD_COMPAT', val);
				}
			},
			latestStep() {
				return this.latestCompletedStep;
			},
			hasRunTime() {
				return typeof this.feedDetails.runtime !== 'undefined' && this.feedDetails.runtime != null &&
					this.feedDetails.runtime !== '';
			},
			availableHours() {
				if (this.feedDetails.import_type != null && this.feedDetails.import_type !== '' && parseInt(this
						.feedDetails.import_type) === 1) {
					return [{
							key: '07:00',
							label: '07:00',
							disabled: false
						},
						{
							key: '15:00',
							label: '15:00',
							disabled: false
						},
						{
							key: '19:00',
							label: '19:00',
							disabled: false
						},
					];
				}

				return this.hours;
			}
		},
		watch: {
			latestStep: {
				handler(step) {
					if (
						(this.feedStep === 4 && step >= 3) || (!this.addProductMode && this.feedStep >= 2 && step >= 2)
					) {
						this.$store.commit('feeds/ENABLE_STEP', 'four');
						if (this.hasRunTime) {
							this.setSavedRunTime();
						}
					} else {
						this.$store.commit('feeds/DISABLE_STEP', 'four');
						this.$store.commit('feeds/TOGGLE_COLLAPSE', {
							status: false,
							step: 'four'
						});
					}
				},
				deep: true,
				immediate: true
			}
		},
		created() {
			EventBus.$on('categAssocDone', () => {
				this.$store.commit('feeds/ENABLE_STEP', 'four');
				this.$store.commit('feeds/CLOSE_STEPTS_EXCEPT', 'four');
			});
			EventBus.$on('assocDone', () => {
				if (!this.addProductMode) {
					this.$store.commit('feeds/ENABLE_STEP', 'four');
					this.$store.commit('feeds/CLOSE_STEPTS_EXCEPT', 'four');
				}
			});
		},
		beforeDestroy() {
			EventBus.$off();
		}
	};
</script>

<style lang="scss">
	@import './src/styles/modules/feeds.scss';
</style>